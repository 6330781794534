<template>
  <div class="d-flex justify-content-between w-100">
    <h4 class="text-custom-blue mb-0">{{ grupo.descricao }}</h4>
    <b-badge class="rounded-pill" :variant="badge.variant">
      {{ badge.label }}
    </b-badge>
  </div>
</template>

<script>
import { BBadge } from "bootstrap-vue";
export default {
  components: {
    BBadge,
  },
  props: {
    vacina: {
      type: Object,
      required: true,
    },
    grupo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      completo: {
        variant: "light-success",
        label: "Completo",
      },
      incompleto: {
        variant: "light-danger",
        label: "Incompleto",
      },
      badge: {},
    };
  },
  watch: {
    "$store.state.campanhaNacionalState.watchers.precificacao": {
      immediate: true,
      handler() {
        this.badge = this.completo;
      },
    },
  },
};
</script>
